<template>
<!-- <ui-grid> -->
  <!-- <ui-grid-cell class="demo-cell" columns="12"> -->
  <h2>Twitch Emoji GIF Maker</h2>
  <input ref="file" v-on:change="handleFileUpload()"  type="file">
  <br>
  <br>
  <p>Frames per second<br>(lower this if your gif ends up with more than 60 total frames)</p>
  <input v-model="fps" type="number">
  <br>
  <br>
  <button @click="transcode">
    Start
  </button>
  <p>{{ message }}</p>
  <img :src="gify">
  <p>Total frames: <strong>{{ frames }}</strong></p>
  <!-- </ui-grid-cell> -->
<!-- </ui-grid> -->
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import * as FFmpeg from '../public/vendor/ffmpeg/ffmpeg/dist/ffmpeg.min.js';
// import { FFmpeg.createFFmpeg, FFmpeg.fetchFile } from '../public/vendor/ffmpeg/ffmpeg/dist/ffmpeg.min.js';
// import BalmUI from 'balm-ui';

export default defineComponent({
  name: 'App',
  setup() {
    // app state
    const ffmpeg = FFmpeg.createFFmpeg({
      log: true,
      corePath: '../ffmpeg-core.js',
      mainName: 'main'
    });
    ffmpeg.load();
    const message = ref('Click Start To Create GIF');
    let video = ref(null);
    let gify = ref(null);
    const file = ref(null);
    const fps = ref(30);
    const handleFileUpload = async() => {
        console.log(fps.value)
        console.log("selected file",file.value.files[0])
    }
    const frames = ref(null)
    // methods
    async function transcode() {
      message.value = 'Start transcoding';
      ffmpeg.FS('writeFile', file.value.files[0].name, await FFmpeg.fetchFile(file.value.files[0]));
      await ffmpeg.run('-i',file.value.files[0].name, '-vf', 'scale=114:114,fps='+fps.value, 'out.gif')
      // await ffmpeg.run('-i', file.value.files[0].name, 'test.mp4');
      message.value = 'GIF is ready!';
      const data = ffmpeg.FS('readFile', 'out.gif');
      gify.value = URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }));
      // video.value = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));


      const arr = new Uint8Array(data.buffer)
      let frame_count = 0
      for (let i = 0; i < arr.length; ++i) {
        if (arr[i] === 0x00 && arr[i+1] === 0x21 &&
          arr[i+2] === 0xF9 && arr[i+3] === 0x04 &&
          arr[i+8] === 0x00 && 
          (arr[i+9] === 0x2C || arr[i+9] === 0x21))
        {
          frame_count++;
        }
      }
      frames.value = frame_count 
    }
    return {
      video,
      gify,
      message,
      transcode,
      handleFileUpload,
      file,
      fps,
      frames
    };
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
